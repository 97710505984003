<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref, watch } from '#imports'
import { onClickOutside } from '@vueuse/core'
import A11yDialog from 'a11y-dialog' // https://a11y-dialog.netlify.app/

const props = defineProps<{
  fullscreen?: boolean
  maxWidth?: string
  modelValue?: boolean
  noBackdrop?: boolean
  persistent?: boolean
}>()

const emit = defineEmits(['hide', 'show', 'update:modelValue'])

const dialogContainer = ref(null as Element | null)
const dialogEl = ref(null)

const dialog = ref(null as A11yDialog | null)

const hide = () => {
  emit('update:modelValue', false)
}
const show = () => {
  emit('update:modelValue', true)
}

onMounted(async () => {
  dialog.value = new A11yDialog(dialogContainer.value as Element)
  dialog.value.on('hide', () => {
    emit('hide')
    hide()
  })

  dialog.value.on('show', () => {
    emit('show')
    show()
  })

  if (props.modelValue) {
    dialog.value.show()
  }
})

onBeforeUnmount(() => {
  dialog.value?.destroy()
})

watch(
  () => props.modelValue,
  (isOpen, prevIsOpen) => {
    if (!dialog.value || isOpen === prevIsOpen) return

    const html = document.documentElement
    if (!isOpen) {
      html.style.overflowY = ''

      dialog.value.hide()
      return
    }

    html.style.overflowY = 'hidden'
    dialog.value.show()
  },
  {
    immediate: true,
  },
)

onClickOutside(dialogEl, () => {
  if (props.persistent) return

  emit('update:modelValue', false)
  emit('hide')
})
</script>

<template>
  <Teleport to="body">
    <!-- TODO: use alert dialog when persistent -->
    <!-- https://a11y-dialog.netlify.app/advanced/alert-dialog -->
    <div
      ref="dialogContainer"
      class="dialog-container z-1066 fixed inset-0 h-screen w-full"
      aria-hidden
    >
      <div
        class="-z-1 absolute inset-0 h-full w-full transform bg-black opacity-70"
        data-a11y-dialog-hide
      />
      <div class="flex h-full flex-col justify-center">
        <div
          ref="dialogEl"
          v-bind="$attrs"
          role="document"
          class="font-poppins card relative mx-auto w-full scale-100 overflow-auto transition"
          :class="{
            'h-screen max-h-screen w-full rounded-none': fullscreen,
            'max-h-90vh container': !fullscreen,
            '-translate-y-3 scale-50 opacity-0': !modelValue,
          }"
          :style="
            fullscreen
              ? {}
              : {
                  maxWidth,
                }
          "
        >
          <slot v-bind="{ hide, open: modelValue, show }" />
        </div>
      </div>
    </div>
  </Teleport>
</template>

<style scoped>
.dialog-container[aria-hidden='true'] {
  display: none;
}
</style>
